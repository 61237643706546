<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-radio-group v-model="q.answer[0].data">
              <b-form-radio value="한다">한다</b-form-radio>
              <b-form-radio value="안한다">안한다</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '한다' ? 'disabled' : ''">
          <b-col class="form-inner-col" sm="7">
            <label>{{ q.answer[1].label[0] }}</label>
            <b-form-radio-group v-model="q.answer[1].data[0]">
              <b-form-radio value="주 1-2회">주 1-2회</b-form-radio>
              <b-form-radio value="주 2-3회">주 2-3회</b-form-radio>
              <b-form-radio value="주 3-4회">주 3-4회</b-form-radio>
              <br />
              <b-form-radio value="주 4-5회">주 4-5회</b-form-radio>
              <b-form-radio value="매일">매일</b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col class="form-inner-col" sm="5">
            <label>{{ q.answer[1].label[1] }}</label>
            <b-form-radio-group v-model="q.answer[1].data[1]">
              <b-form-radio value="강">강</b-form-radio>
              <b-form-radio value="중">중</b-form-radio>
              <b-form-radio value="약">약</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
         <b-row class="form-inner-row" :class="q.answer[0].data !== '한다' ? 'disabled' : ''">
          <b-col class="form-inner-col" sm="7">
            <label>{{ q.answer[1].label[2] }}</label>
            <b-form-radio-group v-model="q.answer[1].data[2]">
              <b-form-radio value="유산소 운동">유산소 운동</b-form-radio>
              <b-form-radio value="저항 운동">저항 운동</b-form-radio>
              <br />
              <b-form-radio value="유산소 운동 + 저항 운동">유산소 운동 + 저항 운동</b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col class="form-inner-col" sm="5">
            <label>{{ q.answer[1].label[3] }}</label>
            <span class="mr-2">평균</span>
            <b-form-input type="text" v-model="q.answer[1].data[3]" />
            <span>분/일</span>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '안한다' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[2].label }}</label>
            <b-form-input type="text" v-model="q.answer[2].data" class="input-lg" />
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q31',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '운동 여부 및 횟수',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '운동 여부 및 횟수',
        answer: [
          {
            data: '',
          },
          {
            label: ['운동 빈도', '운동 강도', '운동 종류', '운동 시간'],
            data: ['', '', '', ''],
          },
          {
            label: '이유',
            data: '',
          },
        ],
      };
    }
    if (this.q.answer[0].data === '안한다') {
      // '안한다' 선택시 '한다' 하위의 질문 답변 초기화
      while (this.q.answer[1].data.length) {
        this.q.answer[1].data.pop();
      }
    }
    if (this.q.answer[0].data === '한다') this.q.answer[2].data = ''; // '한다' 선택시 '안한다' 하위의 질문 답변 초기화
  },
};
</script>